import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Community } from '../types/main.types';

@Injectable({
  providedIn: 'root',
})
export class CommunityService {
  private apiURL = environment.apiUrl;

  constructor(private http: HttpClient) {}

  createCommunity(data: Community) {
    return this.http.post(this.apiURL + '/api/communities', data);
  }

  getCommunity(id: string | null) {
    return this.http.get(`${this.apiURL}/api/communities/${id}`);
  }

  getCommunities(page: number, memberType: string, sortBy: string, size = 20) {
    return this.http.post(
      `${this.apiURL}/api/communities/get?page=${page}&size=${size}${sortBy !== '' ? '&sort=' + sortBy : ''}`,
      { memberType },
    );
  }

  getCommunityMembers(
    communityId: string,
    page: number,
    size = 5,
    search = '',
  ) {
    return this.http.get(
      `${this.apiURL}/api/communities/${communityId}/members?page=${page}&size=${size}${search !== '' ? '&name=' + search : ''}`,
      {},
    );
  }

  getCommunityLeaders(
    communityId: string,
    page: number,
    size = 5,
    search = '',
  ) {
    return this.http.get(
      `${this.apiURL}/api/communities/${communityId}/leaders?page=${page}&size=${size}${search !== '' ? '&name=' + search : ''}`,
      {},
    );
  }

  getCommunityRequests(
    communityId: string,
    page: number,
    size = 5,
    search = '',
  ) {
    return this.http.get(
      `${this.apiURL}/api/communities/${communityId}/requests?page=${page}&size=${size}${search !== '' ? '&name=' + search : ''}`,
      {},
    );
  }

  getCommunityInvites(
    communityId: string,
    page: number,
    size = 5,
    search = '',
  ) {
    return this.http.get(
      `${this.apiURL}/api/communities/${communityId}/invites?page=${page}&size=${size}${search !== '' ? '&name=' + search : ''}`,
      {},
    );
  }

  inviteUser(communityId: string, userId: string) {
    return this.http.post(
      `${this.apiURL}/api/communities/${communityId}/join/invite/${userId}`,
      {},
    );
  }

  acceptInvite(communityId: string) {
    return this.http.post(
      `${this.apiURL}/api/communities/${communityId}/join/invite/accept`,
      {},
    );
  }

  declineInvite(communityId: string) {
    return this.http.post(
      `${this.apiURL}/api/communities/${communityId}/join/invite/decline`,
      {},
    );
  }

  selfRequestJoin(communityId: string) {
    return this.http.post(
      `${this.apiURL}/api/communities/${communityId}/join/request/send`,
      {},
    );
  }

  selfCanelJoin(communityId: string) {
    return this.http.post(
      `${this.apiURL}/api/communities/${communityId}/join/request/cancel`,
      {},
    );
  }

  approve(communityId: string, requestId: string) {
    return this.http.post(
      `${this.apiURL}/api/communities/${communityId}/join/request/approve/${requestId}`,
      {},
    );
  }

  decline(communityId: string, requestId: string) {
    return this.http.post(
      `${this.apiURL}/api/communities/${communityId}/join/request/decline/${requestId}`,
      {},
    );
  }

  leave(communityId: string) {
    return this.http.post(
      `${this.apiURL}/api/communities/${communityId}/leave`,
      {},
    );
  }

  remove(communityId: string, memberId: string) {
    return this.http.delete(
      `${this.apiURL}/api/communities/${communityId}/members/${memberId}`,
    );
  }

  getSettings(communityId: string) {
    return this.http.get(
      `${this.apiURL}/api/communities/${communityId}/settings`,
    );
  }

  uploadLogo(communityId: string, formData: FormData) {
    return this.http.post(
      `${this.apiURL}/api/communities/${communityId}/logo`,
      formData,
    );
  }

  getLogo(communityId: string) {
    return this.http.get(`${this.apiURL}/api/communities/${communityId}/logo`);
  }

  removeLogo(communityId: string) {
    return this.http.delete(
      `${this.apiURL}/api/communities/${communityId}/logo`,
    );
  }

  uploadCoverPhoto(communityId: string, formData: FormData) {
    return this.http.post(
      `${this.apiURL}/api/communities/${communityId}/cover`,
      formData,
    );
  }

  getCoverPhoto(communityId: string) {
    return this.http.get(`${this.apiURL}/api/communities/${communityId}/cover`);
  }

  removeCoverPhoto(communityId: string) {
    return this.http.delete(
      `${this.apiURL}/api/communities/${communityId}/cover`,
    );
  }

  updateSettings(communityId: string, data: any) {
    return this.http.patch(
      `${this.apiURL}/api/communities/${communityId}/settings`,
      data,
    );
  }

  getCommunityHuddles(
    communityId: string,
    page = 0,
    size = 20,
    data: { timeline: string },
  ) {
    return this.http.post(
      `${this.apiURL}/api/communities/${communityId}/huddles/get?page=${page}&size=${size}`,
      data,
    );
  }

  getCommunityBasic(communityId: string) {
    return this.http.get(`${this.apiURL}/api/communities/${communityId}/basic`);
  }

  getCommunitiesJoined() {
    return this.http.post(`${this.apiURL}/api/communities/get?page=0&size=5`, {
      memberType: 'MEMBER',
    });
  }

  getCommunitiesManage() {
    return this.http.post(`${this.apiURL}/api/communities/get?page=0&size=5`, {
      memberType: 'LEADER',
    });
  }

  getCommunitiesRequestAndInvities() {
    return this.http.post(`${this.apiURL}/api/communities/get?page=0&size=5`, {
      memberType: 'REQUESTED_AND_INVITED',
    });
  }

  getCommunitiesDiscovers(page = 0, size = 20) {
    return this.http.get(
      `${this.apiURL}/api/communities/predict?page=${page}&size=${size}`,
    );
  }

  getOverviewSummary() {
    return this.http.get(`${this.apiURL}/api/communities/summary`);
  }

  getCommunityPosts(page: number) {
    return this.http.get(`${this.apiURL}/api/communities/posts?page=${page}`);
  }

  getCommunityPost(communityId: string, page: number) {
    return this.http.get(
      `${this.apiURL}/api/communities/${communityId}/posts?page=${page}`,
    );
  }

  withdrawInvite(communityId: number, communityJoinInviteId: number) {
    return this.http.delete(
      this.apiURL +
        '/api/communities/' +
        communityId +
        '/join/invite/' +
        communityJoinInviteId,
    );
  }
}
